<template>
  <div class="record">
    <div class="headerBox">
      <div class="header">记录</div>
      <div class="recordInfo">
        <div class="tips">剩余可用资产(元)</div>
        <div class="money">{{money}}</div>
        <ul>
          <li>
            <p>{{todayzhanguoInfo.my_todayconvey}}</p>
            <p>今日已接单数</p>
          </li>
          <li>
            <p>{{todayzhanguoInfo.today_yj}}</p>
            <p>今日已抢佣金</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="tabList">
      <van-tabs v-model="tabIndex" @click="selectTab">
        <van-tab title="待收款" name="1">
          <ul v-if="orderlist.length>0">
            <li v-for="(item,index) in orderlist" :key="index">
              <p>抢单时间: {{item.addtime}}</p>
              <p>抢单编号: {{item.id}}</p>
              <div class="status" v-if="item.status == 1">订单匹配中，请稍等</div>
              <div class="finishStatus" v-else-if="item.status == 2">
                <img src="../../../static/img/qiangdna.png" class="stateImg" alt="">
                <div class="cardInfo">
                  <div class="cardImg">
                    <van-image :src="item.goods_pic" width="100" height="100">
                    </van-image>
                  </div>
                  <div class="right">
                    <div class="goods_name">{{item.goods_name}}</div>
                    <div class="flexSpaceBetween">
                      <div>{{item.goods_price}}</div>
                      <div class="goods_count">X {{item.goods_count}}</div>
                    </div>
                </div>
              </div>
                <div class="deeng flexSpaceBetween"><span>订单总额</span> <span>￥{{item.num}}</span></div>
                <div class="deeng flexSpaceBetween"><span>佣金</span> <span>￥{{item.commission}}</span></div>
                <div class="deeng flexSpaceBetween"><span>预计返还</span> <span class="money">￥{{Number(item.num)+Number(item.commission)}}</span></div>
              </div>
            </li>
          </ul>
          <van-empty v-else description="暂无数据" />
        </van-tab>
        <van-tab title="已收款" name="2">
          <ul v-if="orderlist.length>0">
            <li v-for="(item,index) in orderlist" :key="index">
              <p>抢单时间: {{item.addtime}}</p>
              <p>抢单编号: {{item.id}}</p>
              <div class="finishStatus">
                <img src="../../../static/img/chenggong.png" class="stateImg" alt="">
                <div class="cardInfo">
                  <div class="cardImg">
                    <van-image :src="item.goods_pic" width="100" height="100">
                    </van-image>
                  </div>
                  <div class="right">
                    <div class="goods_name">{{item.goods_name}}</div>
                    <div class="flexSpaceBetween">
                      <div>{{item.goods_price}}</div>
                      <div class="goods_count">X {{item.goods_count}}</div>
                    </div>
                  </div>
                </div>
                <div class="deeng flexSpaceBetween"><span>订单总额</span> <span>￥{{item.num}}</span></div>
                <div class="deeng flexSpaceBetween"><span>佣金</span> <span>￥{{item.commission}}</span></div>
                <div class="deeng flexSpaceBetween"><span>预计返还</span> <span class="money">￥{{Number(item.num)+Number(item.commission)}}</span></div>
              </div>
            </li>
          </ul>
          <van-empty v-else description="暂无数据" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>


export default {
  name: "record",
  components: {
  },
  data() {
    return {
      status: 1,     //1-待收款   2-已收款
      money: 0,
      tabIndex: 0,
      orderlist: [],
      todayzhanguoInfo: {},
    }
  },
  mounted() {
    this.getOrderlist()
    this.getMoney()
    this.getTodayzhanguo()
  },
  methods: {
    getOrderlist(){
      $api.orderlist({
        token: this.$store.getters['getToken'],
        status: Number(this.status)
      }).then(res => {
          this.orderlist = res.convey
      })
    },
    getMoney(){
      $api.shoudan({
        token: this.$store.getters['getToken'],
      }).then(res => {
        this.money = res.data.yue
      })
    },
    getTodayzhanguo(){
      $api.todayzhanguo({
        token: this.$store.getters['getToken'],
      }).then(res => {
        this.todayzhanguoInfo = res.message
      })
    },
    selectTab(status){
      this.status = status
      this.getOrderlist()
    }
  }
}
</script>

<style scoped lang="scss">
.record {
  background-color: #f7f7fe;
  height: 100%;
  .headerBox {
    background-image: url("../../../static/img/beijin.png");
    background-size: 100% 320px;
    background-repeat: no-repeat;
    height: 320px;
    width: 100vw;
    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      padding: 0 20px;
      font-size: 17px;
      background: transparent;
      color: #fff;
    }
    .recordInfo {
      width: 90%;
      background-image: url("../../../static/img/toubu.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 204px;
      margin: 30px auto 0;
      padding: 26px 16px;
      color: #fff;
      font-size: 13px;
      .tips {
        text-align: left;
      }
      .money {
        font-size: 32px;
        font-weight: bold;
        margin-top: 20px;
      }
      >ul {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        li {
          width: 155px;
          position: relative;
          p:first-child {
            margin-bottom: 12px;
            font-weight: bold;
            font-size: 20px;
          }
        }
        li:first-child::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          background: #fff;
          width: 1px;
          height: 30px;
          opacity: .3;
        }
      }
    }
  }
  .tabList {
    ul {
      margin-top: 10px;
      li {
        background: #fff;
        width: 90%;
        padding: 15px 10px 10px;
        margin: 10px auto;
        >p {
          text-align: left;
          color: #9e9e9e;
          font-size: 14px;
          margin-bottom: 5px;
        }
        .status {
          font-size: 26px;
          margin-top: 20px;
        }
        .finishStatus {
          position: relative;
          .stateImg {
            width: 80px;
            height: 80px;
            position: absolute;
            right: -10px;
            top: -78px;
            z-index: 2;
          }
          .cardInfo {
            background: #f2f2f2;
            width: 100%;
            padding: 6px;
            margin-top: 10px;
            display: flex;
            font-size: 14px;
            color: #000;
            text-align: left;
            margin-bottom: 20px;
            .right {
              padding-left: 5px;
              .goods_name {
                margin-bottom: 18px;
                margin-top: 5px;
              }
              .goods_count {
                font-size: 13px;
              }
            }

          }
          .deeng {
            margin-top: 8px;
            font-size: 14px;
            span:last-child {
              //font-size: 13px;
            }
            .money {
              color: #ff9a2c;
              font-size: 16px;
              font-weight: bold;
            }
          }
        }

      }
    }
  }

}
</style>
<style>
.van-tabs__line {
  background: linear-gradient(0deg,#ffd879,#ff902e);
  width: 50px;
}
.van-tabs__nav {
  background: transparent;
}
</style>
